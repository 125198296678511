import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardImg from '@mui/material/CardMedia';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import material ui css card styles
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import { Chatbot } from 'react-chatbot-kit';
import school from './school.jpg';
import school2 from './school2.jpg';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/roboto/300-italic.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500-italic.css';
import Typography from '@mui/material/Typography';
import { CardContent, CardHeader, Zoom } from '@mui/material';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
// import ZoomMtgEmbedded from '@zoomus/websdk/embedded'
import './App.css';


// 653ea88afc5e180b7661bae4
// app_653ea96f3020c69153b36a94
// 7SAhJe5N59I83Pd6m55urkEhqZtbQpU-eaKOtzubHO0JZI6Kj1iHoO6iQS9gb65DO_6davsgv5WWZrc8jU_3jw
const backgrounds = [
  'darkslategray',
  'darkslateblue',
  '#2c3e50',
];

const backgroundImages = [
  './school.jpg',
];


function createZendeskTicket() {
  const data = {
    ticket: {
      subject: 'Student Video Conferencing Request',
      description: 'Please join the Zoom meeting for further assistance.',
    },
  };

  fetch('https://k-12menteacademy.zendesk.com/api/v2/tickets.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer k12menteacademyzoomcall',
    },
    body: JSON.stringify(data),
    mode: 'no-cors',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Success:', response);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}



function App() {

  // const client = ZoomMtgEmbedded.createClient()

  // let meetingSDKElement = document.getElementById('meetingSDKElement')

  // client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' })
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showContent0, setShowContent0] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showContent2, setShowContent2] = useState(false);
  const [showContent3, setShowContent3] = useState(false);
  const [showContent4, setShowContent4] = useState(false);
  const [showContent5, setShowContent5] = useState(false);
  const [scrollBackground, setScrollBackground] = useState(school);
  const isMobile = window.matchMedia("(max-width: 767px) and (max-height: 1024px)").matches;
  useEffect(() => {
    createZendeskTicket();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=7fce8f25-194d-41ad-847f-3865754c6bd3';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;

      if (scrollY > 10) {
        setScrollBackground(school2);
      }
      else {
        setScrollBackground(school);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const scrollToContent0 = () => {
    const contentSection0 = document.getElementById('content0');
    if (contentSection0) {
      contentSection0.scrollIntoView({ behavior: 'smooth' });

    }
    setShowContent0(true);
  }

  const scrollToContent = () => {
    const contentSection = document.getElementById('content');
    if (contentSection) {
      contentSection.scrollIntoView({ behavior: 'smooth' });

    }
    setShowContent(true);
  };

  const scrollToContent2 = () => {
    const contentSection2 = document.getElementById('content2');
    if (contentSection2) {
      contentSection2.scrollIntoView({ behavior: 'smooth' });

    }
    setShowContent2(true);
  };

  const scrollToContent3 = () => {
    const contentSection3 = document.getElementById('content3');
    if (contentSection3) {
      contentSection3.scrollIntoView({ behavior: 'smooth' });

    }
    setShowContent3(true);
  };


  const scrollToContent4 = () => {
    const contentSection4 = document.getElementById('content4');
    if (contentSection4) {
      contentSection4.scrollIntoView({ behavior: 'smooth' });

    }
    setShowContent4(true);
  };

  const scrollToContent5 = () => {
    const contentSection5 = document.getElementById('content5');
    if (contentSection5) {
      contentSection5.scrollIntoView({ behavior: 'smooth' });

    }
    setShowContent5(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % backgrounds.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="App">
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=7fce8f25-194d-41ad-847f-3865754c6bd3"> </script>
      <div className={isMobile ? "parallax-mobile" : "parallax"}>
        <div className="parallax__group">

          {backgrounds.map((bg, index) => (
            <div
              key={index}
              className="parallax__layer parallax__layer--back"
              style={{
                backgroundColor: bg,
                // backgroundImage: `url(${backgroundImages[0]})`,
                // transform: `translateZ(-${index * 50}px) scale(${1 + index * 0.2})`,
                opacity: index === currentIndex ? 1 : 0,
                transition: 'opacity 1s, transform 0.1s'
              }}
            >
              <Card style={{ alignItems: 'center', marginTop: "8%", marginLeft: "12%", height: '100%', width: "75%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", border: "#141823", borderRadius: "0%", backgroundColor: "#141823" }}>

                {!isMobile && (<img src={scrollBackground} ></img>)}
              </Card>
            </div>
          ))}
          <div style={{ paddingBottom: "50%" }} className="parallax__layer parallax__layer--base">
            <div className="content">
              <div className="lightBulb">
                <div className="bulb"></div>
              </div>
              <Typography variant='h2'>Welcome to K-12 Mente Academy</Typography>
              <Typography variant='h4' style={{ padding: '5%' }}>Learning with  <span className="strikethrough">pampers</span> Purpose</Typography>


              {!isMobile && (<>{showContent0 ? <Typography style={{ color: "darkred" }}>Warning: World awaits for those who scroll!</Typography> : <button onClick={scrollToContent0}>Explore World</button>}</>)}
            </div>
          </div>


          {showContent0 && (

            <div id='content0' style={{ alignItems: 'center', backgroundColor: "transparent" }} className="content-section">


              <Typography style={{ padding: '4%' }} variant='h4'>What we do?</Typography>

              <KeyboardArrowDownIcon style={{ paddingBottom: '20%', padding: '1%', fontSize: 40 }} onClick={scrollToContent5} />

              {showContent5 && (

                <Typography style={{ justifyContent: 'true' }} id='content5' variant='h5' style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                  The K-12 Mente Academy is an innovative tutoring platform that provides a <b>comprehensive</b> and <b>adaptive learning</b> experience for K-12 students.
                  Our solution utilizes technology and <b>data-driven insights</b> to address the challenges associated with traditional tutoring methods and offer a <b>personalized, engaging, and interactive</b> learning environment.
                </Typography>
              )}


              <Typography style={{ padding: '4%' }} variant='h4'>Why build a Modern Learning System?</Typography>
              <KeyboardArrowDownIcon style={{ paddingBottom: '20%', padding: '1%', fontSize: 40 }} onClick={scrollToContent} />


              {showContent && (

                <Typography style={{ justifyContent: 'true' }} id='content' variant='h5'>
                  Becuase we want our <b>children</b> to learn and grow so<br></br> we can leave the future of the <b>universe</b> in good hands.
                </Typography>

              )}


              <Typography style={{ padding: '4%', justifyContent: 'true' }} variant='h4'>How do we make it happen?</Typography>

              <KeyboardArrowDownIcon style={{ paddingBottom: '20%', padding: '1%', fontSize: 40 }} onClick={scrollToContent2} />

              {showContent2 && (

                <Typography style={{ justifyContent: 'true' }} id='content2' variant='h5'>
                  We make it happen by creating a <b>path-breaking</b> system<br></br> for <b>personalized and family oriented</b> learning.
                </Typography>
              )}



              <Typography style={{ padding: '4%' }} variant='h4'>Message from the founder <b>Delores Jones</b></Typography>

              <KeyboardArrowDownIcon style={{ paddingBottom: '20%', padding: '1%', fontSize: 40 }} onClick={scrollToContent4} />

              {showContent4 && (
                <>
                  <Typography id='content4' variant='h5' style={{ paddingLeft: "10%", paddingRight: "10%", justifyContent: 'true' }}>

                    We are in the process of building a whole new <b>ecosystem</b><br></br> for <b>innovation and excellence</b>.</Typography>

                  <Typography variant='h6' style={{ paddingLeft: "5%", paddingRight: "5%", justifyContent: 'true' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'true' }}>
                      <Card style={{ padding: "5%", marginTop: "5%", marginLeft: "3%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", backgroundColor: "rgba(0,0,0,0.1)" }}><CardHeader style={{ color: "#2c3e50" }} title="Catering to the Students' Unique Requirements" /><CardContent>Our platform caters to the unique requirements of each student, adapting to their current knowledge levels, learning speeds, and individual preferences. We provide targeted support where needed, bridging academic gaps and ensuring accessibility for all students, regardless of their geographic location or physical abilities. At K-12 Mente Academy, we prioritize personalized learning, interactivity, and holistic student support.</CardContent>
                      </Card><Card style={{ padding: "5%", marginTop: "5%", marginLeft: "3%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", backgroundColor: "rgba(0,0,0,0.1)" }}><CardHeader style={{ color: "#2c3e50" }} title="Fostering a Love for Learning" />Our platform offers a well-rounded online educational experience that addresses the specific needs of K-12 students, empowering them to succeed academically and personally. We foster a love for learning and build a strong foundation for future educational endeavors.
                      </Card><Card style={{ padding: "5%", marginTop: "5%", marginLeft: "3%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", backgroundColor: "rgba(0,0,0,0.1)" }}><CardHeader style={{ color: "#2c3e50" }} title="Incorporating Continuous Feedback" />Our commitment to continuous feedback mechanisms and ongoing improvement sets us apart from existing solutions. We are dedicated to remaining at the forefront of educational innovation, incorporating interactive content, peer learning opportunities, and parental involvement to create a dynamic and engaging learning environment.
                      </Card><Card style={{ padding: "5%", marginTop: "5%", marginBottom: "5%", marginLeft: "3%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", backgroundColor: "rgba(0,0,0,0.1)" }}><CardHeader style={{ color: "#2c3e50" }} title="Having Clearly Defined Goals" />Our goal at K-12 Mente Academy is to empower students to achieve academic success and reach their full potential while fostering a lifelong love for learning. With our comprehensive, student-centric, and holistic approach, we aim to revolutionize online tutoring for K-12 students and set a new standard for modern education.
                      </Card></div></Typography>
                </>
              )}




              {/* <Typography style={{ padding: '4%' }} variant='h3'>Stories from our certified teachers</Typography>

              <KeyboardArrowDownIcon style={{ paddingBottom: '20%', padding: '1%', fontSize: 40 }} onClick={scrollToContent3}>Explore</KeyboardArrowDownIcon>

              {showContent3 && (<div >

                <Typography id='content3' variant='h4'>
                  We go through a highly <b>selective </b> process<br></br> to create a global <b>equal </b> opportunity-based staffing culture.
                  <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", marginLeft: "15%", marginRight: "15%" }}>
                    <Card style={{ marginTop: "5%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", backgroundColor: "slateblue" }}>
                      <CardImg></CardImg>
                      <CardHeader title="Sneha" subheader="Math Teacher" />
                      <CardContent style={{ padding: "5%", backgroundColor: "darkslateblue" }}>
                        <Typography style={{ color: "darkgray" }} variant='h6'>I am a certified math teacher with 5 years of experience teaching in a public school.
                          I have a Masters in Education and a Bachelors in Mathematics.
                          I am passionate about teaching and helping students reach their full potential.
                          I believe that every child can learn and I am here to help them achieve their goals.
                          I am looking forward to working with you and your child.</Typography>
                      </CardContent>
                    </Card>
                    <Card style={{ marginTop: "5%", marginLeft: "15%", boxShadow: "20px 13px 23px -4px rgba(0,0,0,0.5)", backgroundColor: "slateblue" }}>
                      <CardHeader title="Shruti" subheader="Math Teacher" />
                      <CardContent style={{ padding: "5%", backgroundColor: "darkslateblue" }}>
                        <Typography style={{ color: "darkgray" }} variant='h6'>I am a certified math teacher with 5 years of experience teaching in a public school. I have a Masters in Education and a Bachelors in Mathematics. I am passionate about teaching and helping students reach their full potential. I believe that every child can learn and I am here to help them achieve their goals. I am looking forward to working with you and your child.</Typography>
                      </CardContent>
                    </Card>
                  </div>

                </Typography>

              </div>
              )} */}

            </div>
          )}
        </div>

      </div>
      {/* <div id="meetingSDKElement">
      </div> */}
    </div>
  );
}

export default App;
